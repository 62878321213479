import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AboutSection = () => {
  const [about, setAbout] = useState([]);
  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);

  return (
 <>
     {
        about.map(a => <section className="about-style-three pb_150" id="about-sec">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image_block_five">
                  <div className="image-box mr_80">
                    <figure className="image"><img src={a.aboutImgOne} alt /></figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_four">
                  <div className="content-box ml_40">
                    <div data-animation-box className="sec-title mb_25">
                      <span data-animation-text className="sub-title " >{a.aboutText}</span>
                      <h2>{a.aboutHeading}</h2>
                    </div>
                    <div className="text-box mb_40">
                      <p>{a.aboutDetails}</p>
                    </div>
                    <div className="inner-box">
                      <div className="single-item mb_40">
                        <div className="icon-box"><i className="icon-13" /></div>
                        <h3>{a.pointOne}</h3>
                        <p>{a.pointOneText}</p>
                      </div>
                      <div className="single-item">
                        <div className="icon-box"><i className="icon-10" /></div>
                        <h3>{a.pointTwo}</h3>
                        <p>{a.pointTwoText}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>)}
    </>
  );
};

export default AboutSection;
