import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createPopper } from '@popperjs/core';

import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Bootstrap's JS includes Popper.js

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const NavBar = () => {
  const [user] = useAuthState(auth);
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleSignOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logoResponse = await fetch("https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/logo");
        const logoData = await logoResponse.json();
        setLogo(logoData);

        const usersResponse = await fetch("https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/users");
        const usersData = await usersResponse.json();
        setUsers(usersData);

        const profileResponse = await fetch("https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/profiles/");
        const profileData = await profileResponse.json();
        setProfile(profileData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


const button = document.querySelector('#button');
const tooltip = document.querySelector('#tooltip');

const popperInstance = createPopper(button, tooltip, {
  placement: 'right', // Adjust the position as needed
});

  return (
    <>
      {
        logo.map(l => <div>
          {/* main header */}
          <header className="main-header header-style-five">
            {/* header-lower */}
            <div className="header-lower">
              <div className="auto-container">
                <div className="outer-box">
                  <div className="logo-box">
                    <figure className="logo mt-3"><a href="/"><img src={l.logo} alt /></a></figure>
                  </div>
                  <div className="menu-area">
                    {/*Mobile Navigation Toggler*/}
                    <div className="mobile-nav-toggler">
                      <i className="icon-bar" />
                      <i className="icon-bar" />
                      <i className="icon-bar" />
                    </div>
                    <nav className="main-menu navbar-expand-md navbar-light clearfix">
                      <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul className="navigation clearfix">

                          <li><a href="/">Home</a></li>
                          <li><a href="/real-estate-email-database">Real Estate Email Database</a></li>
                          <li><a href="/real-estate-email-database/#pricing">Pricing</a></li>
                  
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="menu-right-content">
                    {user?.email ? (
                      <ul className="list-unstyled">
                      <li className="nav-item d-flex align-items-center">
                        <Link to="/dashboard" className="theme-btn btn-one mt-3">
                          Dashboard
                        </Link>
                      </li>
                    </ul>
                    ) : (
                      <ul className="list-unstyled">
                        <li className="nav-item d-flex align-items-center">
                          <Link to="/login" className="theme-btn btn-one mt-3">
                            Log in
                          </Link>
                        </li>
                      </ul>
                    )}

                    <div className="side-item">
                      {users.map(
                        (u, index) =>
                          u.userEmail === user?.email &&
                          u.userRole === "Admin" && (
                            <li className="nav-item d-flex align-items-center" key={index}>
                              <Link to="/admin" className="theme-btn btn-one">
                                Admin
                              </Link>
                            </li>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*sticky Header*/}
            <div className="sticky-header">
              <div className="auto-container">
                <div className="outer-box">
                  <div className="logo-box">
                    <figure className="logo mt-3"><a href="/"><img src={l.logo} alt /></a></figure>
                  </div>
                  <div className="menu-area">
                    <nav className="main-menu clearfix">
                      <ul className="navigation clearfix">

                        <li><a href="/">Home</a></li>
                        <li><a href="/real-estate-email-database">Real Estate Email Database</a></li>
                        <li><a href="/real-estate-email-database/#pricing">Pricing</a></li>

                      </ul>
                    </nav>
                  </div>
                  <div className="menu-right-content">
                    {user?.email ? (
                     <ul className="list-unstyled">
                     <li className="nav-item d-flex align-items-center">
                       <Link to="/dashboard" className="theme-btn btn-one mt-3">
                         Dashboard
                       </Link>
                     </li>
                   </ul>
                    ) : (
                      <ul className="list-unstyled">
                        <li className="nav-item d-flex align-items-center">
                          <Link to="/login" className="theme-btn btn-one mt-3">
                            Log in
                          </Link>
                        </li>
                      </ul>

                    )}
      <div className="side-item">
                      {users.map(
                        (u, index) =>
                          u.userEmail === user?.email &&
                          u.userRole === "Admin" && (
                            <li className="nav-item d-flex align-items-center" key={index}>
                              <Link to="/admin" className="theme-btn btn-one">
                                Admin
                              </Link>
                            </li>
                          )
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </header>
          {/* main-header end */}
          {/* Mobile Menu  */}
          <div className="mobile-menu">
            <div />

            {user?.email ? (
              <>
                {profile.map(
                  (pro) =>
                    pro.userEmail === user?.email && (
                      <div
                        className="nav-item dropdown"
                        key={pro._id}
                      >
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={

                              "https://i.postimg.cc/NjxGmyRC/icons8-hamburger-menu-50.png"
                            }
                            alt="Profile"

                            style={{
                              width: "40px",
                              height: "40px",

                            }}
                          />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end p-3">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/dashboard"
                            >
                              <i className="fa-solid fa-bars text-primary"></i>{" "}
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/my-order/"
                            >
                              <i className="fa-solid fa-cart-shopping text-primary"></i>{" "}
                              My Orders
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <i className="fa-solid fa-user text-primary"></i>{" "}
                            {pro.userName}
                          </li>
                          <li>{pro.userEmail}</li>
                          <Link
                            to={`/update-profile/${pro._id}`}
                            className=""
                          >
                            Edit Profile
                            <span className=""></span>
                          </Link>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={handleSignOut}
                            >
                              <i className="fa-solid fa-right-from-bracket text-primary"></i>
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    )
                )}
              </>
            ) : (
              <li className="nav-item" style={{ listStyleType: 'none' }}>
                <Link to="/login" className="btn btn-outline-info btn-lg mx-auto mb-2 text-decoration-none">
                  Log in
                </Link>
              </li>
            )}

          </div>{/* End Mobile Menu */}
        </div>)
      }


    </>
  );
};

export default NavBar;
