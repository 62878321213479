import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;




    const addItem = {
      subemail,



    };

    const url = `https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Subscribers Request is Sent');
      });


  }




  return (



    <>
      <div>
        {
          footerSocial.map(s =>
            <footer className="main-footer">
              <div className="auto-container">
                <div className="widget-section">
                  <div className="row clearfix">


                    <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget logo-widget">
                        {
                          logo.map(l => <figure className="footer-logo"><a href="/"><img src={l.logo} alt /></a></figure>)}

                        <p>{s.aboutText}</p>
                        <ul className="social-links clearfix">
                          <li><a href={s.fblink} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook" /></a></li>
                          <li><a href={s.inslink} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram" /></a></li>
                          <li><a href={s.youlink} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-youtube" /></a></li>

                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget links-widget ml_60">
                        <div className="widget-title">
                          <h3>Quick Links</h3>
                        </div>
                        <div className="widget-content">
                          <ul className="links-list clearfix">
                            <li><a href="/">Home</a></li>
                            <li><a href="/real-estate-email-database#pricing">Pricing</a></li>
                            <li><a href="#contact-us">Contact</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget subscribe-widget">
                        <div className="widget-title">
                          <h3>Subscribe Newsletter</h3>
                        </div>
                        <div className="widget-content">
                          <p>Subscribe to our newsletter for exclusive updates, valuable insights, and special offers delivered directly to you!</p>
                          <div className="form-inner">
                            <form onSubmit={handleSubscriber} method="post" >
                              <div className="form-group">
                                <input type="email" name="subemail" placeholder="Email Address" required />
                                <button type="submit" className="theme-btn btn-one">Subscribe</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom centred">
                <div className="auto-container">
                  {
                    footerCopyright.map(c => <div className="copyright"><p>{c.copyrightText}</p></div>)}
                </div>
              </div>
            </footer>
          )}
        {/* main-footer end */}

      </div>
    </>
  );
};

export default Footer;
