import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const RealEstateServiceSection = () => {
  const [service, setService] = useState([]);
  const [itemsreal, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/service-real-estate`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/service-items-real-estate`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  const handleAddItem = (event) => {
    event.preventDefault();

    const serviceIconReal = event.target.serviceIconReal.value;
    const serviceTitleReal = event.target.serviceTitleReal.value;
    const serviceDetailsReal = event.target.serviceDetailsReal.value;

    const itemSection = {
      serviceIconReal,
      serviceTitleReal,
      serviceDetailsReal,
    };

    const url = `https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/add-service-real-estate`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(itemSection),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Item is Added');
      });
  };

  return (
    <>

      <HeaderBottom></HeaderBottom>
      <section id="services" className="services-area  d-flex justify-content-center align-items-center">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">
              <h2 className="mt-5 text-center">Update Service Text</h2>
              {service.length === 1 && (
                <>
                  {service.map((s) => (
                    <Link
                      className="theme-btn btn-one my-3"
                      to={`/servicetext-edit/${s._id}`}
                    >
                      Update Service
                    </Link>
                  ))}
                </>
              )}
           

              <h2 className="mt-5 mb-5">Add Service</h2>
              <form className="contact-form" onSubmit={handleAddItem}>
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg shadow-lg"
                        name="serviceIconReal"
                        placeholder="Service Image"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg shadow-lg"
                        name="serviceTitleReal"
                        placeholder="Service Title"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg shadow-lg"
                        name="serviceDetailsReal"
                        placeholder="Service Details"
                        required
                      />
                    </div>
                  </div>
                  <div className="slider-btn">
                    <button
                      className="theme-btn btn-one"
                      data-animation="fadeInRight"
                      data-delay=".8s"
                    >
                      Add Service
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Service Items List */}
          <div className="row justify-content-center mt-5">
            {itemsreal.map((s) => (
              <div className="col-lg-4 col-md-6 mb-4 d-flex justify-content-center" key={s._id}>
                <div className="card h-100 shadow-sm">
                  <div className="card-body">
                    <h4 className="card-title">{s.serviceTitleReal}</h4>
                    <p className="card-text">{s.serviceDetailsReal}</p>
                    <Link
                      to={`/real-estate-service-edit/${s._id}`}
                      className="theme-btn btn-one mt-2"
                    >
                      Edit Services
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <HeaderBottom></HeaderBottom>
    </>
  );
};

export default RealEstateServiceSection;
