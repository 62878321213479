import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const UpdateBannerSection = () => {
    const [banner, setBanner] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/banner/${id}`)
            .then((res) => res.json())
            .then((info) => setBanner(info));
    }, [id]);

    const handleBannerSection = (event) => {
        event.preventDefault();
        const bannerHeadingOne = event.target.bannerHeadingOne.value;
        const bannerHeadingTwo = event.target.bannerHeadingTwo.value;
        const bannerHeadingThree = event.target.bannerHeadingThree.value;
        const bannerDetails = event.target.bannerDetails.value;
        const imageOne = event.target.imageOne.value;
        const buttonText = event.target.buttonText.value;
        const buttonURL = event.target.buttonURL.value;
        const buttonTextTwo = event.target.buttonTextTwo.value;
        const buttonURLTwo = event.target.buttonURLTwo.value;

        const bannerSection = {
            bannerHeadingOne,
            bannerHeadingTwo,
            bannerHeadingThree,
            bannerDetails,
            imageOne,
            buttonText,
            buttonURL,
            buttonTextTwo,
            buttonURLTwo,
        };

        const url = `https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/update-banner/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bannerSection),
        })
            .then((res) => res.json())
            .then((result) => {
                alert('Banner is Updated');
            });
    };

    return (
        <>
            
            <div>
                <section id="services" className="services-area vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <h2 className='mb-5 mt-5'>Update Banner</h2>
                                <form className="contact-form" onSubmit={handleBannerSection}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingOne" defaultValue={banner.bannerHeadingOne} placeholder="Banner Heading One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingTwo" defaultValue={banner.bannerHeadingTwo} placeholder="Banner Heading Two" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerHeadingThree" defaultValue={banner.bannerHeadingThree} placeholder="Banner Heading Three" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="bannerDetails" defaultValue={banner.bannerDetails} placeholder="Banner Details " required />
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="imageOne" defaultValue={banner.imageOne} placeholder="Image One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonText" defaultValue={banner.buttonText} placeholder="Button Text One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonURL" defaultValue={banner.buttonURL} placeholder="Button URL One" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonTextTwo" defaultValue={banner.buttonTextTwo} placeholder="Button Text Two" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 pb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="buttonURLTwo" defaultValue={banner.buttonURLTwo} placeholder="Button URL Two" required />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 text-center">
                                            <button className="theme-btn btn-one mt-4">Update Banner</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <HeaderBottom />
            </div>
        </>
    );
};

export default UpdateBannerSection;
