import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EditServiceRealEstate = () => {
    const { id } = useParams();
    const [item, setItem] = useState([]);

    useEffect(() => {
        fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/service-real-estate/${id}`)
            .then((res) => res.json())
            .then((info) => setItem(info));
    }, []);

    const handleAddItem = (event) => {
        event.preventDefault();

        const serviceIconReal = event.target.serviceIconReal.value;
        const serviceTitleReal = event.target.serviceTitleReal.value;
        const serviceDetailsReal = event.target.serviceDetailsReal.value;

        const itemSection = {

            serviceIconReal,
            serviceTitleReal,
            serviceDetailsReal,
        };

        const url = `https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/service-real-estate-update/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(itemSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert('Item is Updated');
            });
    };


    return (
        <>

        

            <div className='container  vh-100 d-flex align-items-center justify-content-center'>
                <form class="contact-form" onSubmit={handleAddItem}>
                    <div class="row">

                        <div className="col-lg-12 col-md-12 mb-3">
                            <div className="form-group">
                                <input type="text" className="form-control" name="serviceIconReal" defaultValue={item.serviceIconReal} placeholder="Service Image" required />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 mb-3">
                            <div className="form-group">
                                <input type="text" className="form-control" name="serviceTitleReal" defaultValue={item.serviceTitleReal} placeholder="Service Title" required />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 mb-3">
                            <div className="form-group">
                                <input type="text" className="form-control" name="serviceDetailsReal" defaultValue={item.serviceDetailsReal} placeholder="Service Details" required />
                            </div>
                        </div>



                        <div class="slider-btn">
                            <button class="theme-btn btn-one mb-5" data-animation="fadeInRight" data-delay=".8s"> Update service </button>
                        </div>
                    </div>

                </form>

            </div>

        </>
    );
};

export default EditServiceRealEstate;