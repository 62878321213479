import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const RealEstateDB = () => {
  const [realEstateDB, setRealEstateDB] = useState([]);
  useEffect(() => {
    fetch(`https://raw.githubusercontent.com/Shah-Limon/canva-image-4-projects/refs/heads/main/real-estate.json`)
      .then((res) => res.json())
      .then((info) => setRealEstateDB(info));
  }, []);

  return (
 <div className="m-5">
 <table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Job Title</th>
      <th scope="col">Website</th>
      <th scope="col">Email</th>
      <th scope="col">Company Name</th>
      <th scope="col">Total Employees</th>
      <th scope="col">Revenue</th>
      <th scope="col">LinkedIn</th>
      <th scope="col">Facebook</th>
      <th scope="col">Company Email</th>
      <th scope="col">CMS Type</th>
      <th scope="col">SEO Score</th>
      <th scope="col">Loading Speed</th>
      <th scope="col">Address</th>
    </tr>
  </thead>

</table>
     {
        realEstateDB.slice(1, 10).map(real => 
            <tbody>
            <tr>
              <td>{real.name}</td>
              <td>{real.jobTitle}</td>
              <td>{real.website}</td>
              <td>{real.email}</td>
              <td>{real.companyName}</td>
              <td>{real.totalEmploye}</td>
              <td>{real.revenue}</td>
              <td>-</td>
              <td>-</td>
              <td>{real.companyEmail}</td>
              <td>{real.cmsType}</td>
              <td>{real.onePageSeoScore}</td>
              <td>Poor</td>
              <td>{real.address}</td>
            </tr>
           
          </tbody>
        )
     }
    </div>
  );
};

export default RealEstateDB;
