import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';


const BannerForrealEstate = () => {
  const [banners, setBanners] = useState([]);
  
  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/banner-real`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);

  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const subscribe = {
      subscriberEmail
    };

    const url = `https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(subscribe),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Thanks For Subscribing!');
      });
  };

  const RatingStars = ({ rating, totalRatings, profileIcons }) => {
    return (
      <div className="rating">
        <div className="stars">
          {[...Array(5)].map((star, index) => (
            <span key={index} className={index < rating ? "filled" : ""}>
              ★
            </span>
          ))}
        </div>
        <div className="rating-info">
          <span>{totalRatings} Ratings</span>
          <div className="profile-icons">
            {profileIcons.map((icon, index) => (
              <img key={index} src={icon} alt="Profile" className="profile-icon" />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {
        banners.map(b => (
          <section className="banner-style-five centred" key={b.id}>
            <div className="pattern-layer">
              <div className="pattern-1" style={{ backgroundImage: 'url(assets/images/shape/shape-27.png)' }} />
              <div className="pattern-2" style={{ backgroundImage: 'url(assets/images/shape/shape-28.png)' }} />
              <div className="pattern-3 float-bob-y" style={{ backgroundImage: 'url(assets/images/shape/shape-29.png)' }} />
              <div className="pattern-4 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-30.png)' }} />
              <div className="pattern-5 float-bob-y" style={{ backgroundImage: 'url(assets/images/shape/shape-31.png)' }} />
              <div className="pattern-6" style={{ backgroundImage: 'url(assets/images/shape/shape-32.png)' }} />
            </div>
            <div className="auto-container">
              <div className="inner-box">
                <div className="content-box mb_80">
                  <h1>{b.bannerHeadingReal} <span>{b.bannerTextRealTwo}</span><br />{b.bannerTextReal}</h1>
                  <p>{b.bannerDetailsReal}</p>
                  <RatingStars 
                    rating={4.5}  // Example rating
                    totalRatings={75}  // Example total ratings
                    profileIcons={['https://img.freepik.com/free-vector/profile-picture-template-design_742173-22027.jpg', 'https://img.freepik.com/free-photo/close-up-shot-hopeful-optimistic-happy-young-redhead-20s-girl-with-freckles-long-hair-smiling-joyfully-with-faith-eyes-prominent-look-posing-against-purple-background_1258-81590.jpg', 'https://img.freepik.com/free-photo/bearded-man-with-striped-shirt_273609-7180.jpg']} // Replace with actual paths
                  />
                  <div className="btn-box">
                    <a href={b.buttonURLReal} className="theme-btn btn-one mr_20">{b.buttonTextReal}</a>
                    <a href={b.buttonURLTwoReal} className="theme-btn btn-two">{b.buttonTextTwoReal}</a>
                  </div>
                </div>
                <figure className="image-box"><img src='https://i.postimg.cc/mhWPYC96/Real-Estate-Database.jpg' alt="real-estate-database" /></figure>
              </div>
            </div>
          </section>
        ))
      }
    </>
  );
};

export default BannerForrealEstate;
