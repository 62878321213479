import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const VideoModal = ({ isOpen, onClose, videoUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="modal fade show" style={{ display: 'block' }} onClick={onClose}>
      <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={onClose}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <iframe
              width="100%"
              height="500"
              src={videoUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

const WorkSectionforRealEstate = () => {
  const [stepwork, setWork] = useState([]);
  const [video, setVideo] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  useEffect(() => {
    // Fetch video data
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/videos-real`)
      .then((res) => res.json())
      .then((info) => setVideo(info));
  }, []);

  useEffect(() => {
    // Fetch work steps data
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/worksec-real`)
      .then((res) => res.json())
      .then((info) => setWork(info));
  }, []);

  const openModal = (url) => {
    const videoId = url.split('/').pop(); // Extracts the video ID from the URL
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    setCurrentVideoUrl(embedUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentVideoUrl('');
  };

  return (
    <>
      {video.map(v => (
        <section className="working-style-three centred pb_120" key={v._id}>
          <div className="pattern-layer">
            <div className="pattern-1 float-bob-y" style={{ backgroundImage: 'url(assets/images/shape/shape-39.png)' }} />
            <div className="pattern-2 float-bob-y" style={{ backgroundImage: 'url(assets/images/shape/shape-31.png)' }} />
          </div>
          <div className="auto-container">
            <div data-animation-box className="sec-title mb_55">
              <h2>Connect with Agents: Our Step-by-Step Guide</h2>
            </div>
            <div className="upper-box mb_50 p_relative border-2">
              <div className="image-shape">
                <div className="shape-1" style={{ backgroundImage: 'url(assets/images/shape/shape-36.png)' }} />
                <div className="shape-2 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-37.png)' }} />
                <div className="shape-3 rotate-me" style={{ backgroundImage: 'url(assets/images/shape/shape-38.png)' }} />
              </div>
              <div className="video-inner" style={{ backgroundImage: `url(${v.videoImageReal})` }}>
                <div className="video-btn">
                  <button
                    className="btn btn-xlg btn-dark"
                    onClick={() => openModal(v.videoLinkReal)}
                  >
                    <i className="icon-15" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              {stepwork.map(step => (
                <div className="col-lg-4 col-md-6 col-sm-12 working-block" key={step._id}>
                  <div className="working-block-two">
                    <div className="inner-box">
                      <div className="shape" style={{ backgroundImage: 'url(assets/images/shape/shape-19.png)' }} />
                      <div className="icon-box">{step.stepNumberReal}</div>
                      <h3>{step.stepTitleReal}</h3>
                      <p>{step.stepDetailsReal}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ))}

      <VideoModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        videoUrl={currentVideoUrl} 
      />
    </>
  );
};

export default WorkSectionforRealEstate;
