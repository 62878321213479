import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);
  const [footerSocial, setFooterSocial] = useState([]);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;

    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,
    };

    const url = `https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Message is sent');
        navigate('/thanks');  // Navigate to /thanks route
      })
      .catch((error) => {
        console.error('Error:', error);

      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://realestatedbproduct1-36a0701ff2a5.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);

  return (

    <> <div >
      {/* contact-section */}
      <section className="contact-section  pb_150" id='contact-us'>
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 centred">
            <span data-animation-text className="sub-title ">Contact Now</span>
            <h2>Get in Touch</h2>
          </div>
          <div className="form-inner">
            <form onSubmit={handleMessage} method="post" id="contact-form">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="name" placeholder="Your Name" required />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="email" name="email" placeholder="Your email" required />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="number" required placeholder="Phone" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="subject" required placeholder="Subject" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    name="messageStatus"
                    value="UnRead"
                    hidden
                    placeholder="Message Status"
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <textarea name="message" placeholder="Type message" defaultValue={""} />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                  <button className="theme-btn btn-one" type="submit" name="submit-form">Message Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* contact-section end */}
    </div>
    </>
  );
};

export default ContactUs;
