// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFJicnpygrJRQOM6djpi1z2hItWGvpuTA",
  authDomain: "leadisking-d4355.firebaseapp.com",
  projectId: "leadisking-d4355",
  storageBucket: "leadisking-d4355.appspot.com",
  messagingSenderId: "618735570060",
  appId: "1:618735570060:web:6a30e061f10bc078027fa9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;